import React, { useEffect } from 'react';
import AOS from 'aos';

import AboutTenClub from '../components/LandingPage/AboutTenClub';
import HeroSection from '../components/LandingPage/HeroSection';
import JoinUs from '../components/LandingPage/JoinUs';
import SecretToSuccess from '../components/LandingPage/SecretToSuccess';
import DataInsights from '../components/LandingPage/DataInsights';
import Model from '../components/LandingPage/Model';
import Layout from '../components/Layout';
import SellersStories from '../components/LandingPage/SellersStories';
import PressSection from '../components/LandingPage/PressSection';
import {useStaticQuery,graphql} from 'gatsby'


function App() {
const query = useStaticQuery(graphql`
query Home {
  allSanityHomepage {
    edges {
      node {
        heroTitle
        heroDescription
        heroGif {
          asset {
            url
          }
        }
        AccordionTitle1
        AccordionDescription1
        accordion1 {
          title
          description
        }
        button1
        insightsTitle
        insightsDesc
        insightsGif {
          asset {
            url
          }
        }
        AccordionTitle2
        AccordionDescription2
        accordion2 {
          title
          description
        }
        button2
        coFounderTitle
        coFounderDescription
        coFounder {
          coFounderName
          position
          linkedInUrl
          coFounderImage {
            asset {
              url
            }
          }
        }
        pressTitle
        press {
          pressHeading
          pressSubtext
          pressLink
          pressImage {
            asset {
              url
            }
          }
        }
        joinUsTitle
        joinUsDesc
        joinUsButton
        buttonLink
      }
    }
  }
}
`)
  useEffect(() => {
    AOS.init({disable: false});
    AOS.refresh();
  }, []);
  
  const {allSanityHomepage:{edges:{0:{node:pageData}}}}=query;
  return (
    <>
      <Layout bgBlack={false} getInTouchActive={true}>
        <HeroSection title={pageData.heroTitle} description={pageData.heroDescription} heroGif={pageData.heroGif}/>
        <Model accordionData={pageData.accordion1} title={pageData.AccordionTitle1} description={pageData.AccordionDescription1} buttonText={pageData.button1}/>
        <DataInsights title={pageData.insightsTitle} description={pageData.insightsDesc} gif={pageData.insightsGif}/>
        {/* <AboutTenClub accordionData={pageData.accordion2} title={pageData.AccordionTitle2} description={pageData.AccordionDescription2} buttonText={pageData.button2}/> */}
        <SecretToSuccess title={pageData.coFounderTitle} description={pageData.coFounderDescription} slides={pageData.coFounder}/>
        <div className="background-black">
        <PressSection title={pageData.pressTitle} press={pageData.press}/>
        </div>
        <JoinUs title={pageData.joinUsTitle} description={pageData.joinUsDesc} buttonText={pageData.joinUsButton} link={pageData.buttonLink}/>
      </Layout>
    </>
  );
}

export default App;


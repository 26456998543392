import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/JobsPage/HeroSection";
import JoinUs from "../components/JobsPage/JoinUs";
import Layout from "../components/Layout";


export default function Job({ pageContext: { job } }) {
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{job.title} | 10Club</title>
      </Helmet>
      <Layout bgBlack={false} getInTouchActive={false}>
        <HeroSection job={job} />
        <JoinUs jobTitle={job.title} />
      </Layout>
    </>
  );
}

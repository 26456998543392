import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react'
import styled from "styled-components";

import arrow from "../../images/icons/downArrow.svg"
import BlockContent from "@sanity/block-content-to-react";

const StyledWrapper = styled.div`
    background-size: 16px 16px;
    padding-top: 80px;
    padding-bottom: 80px;

    button {
        width: 64px;
        height: 64px;
        border: 1px solid;
        @media(max-width: 576px) {
            width: 40px;
            height: 40px;
        }
    }

    button img {
        transform: rotate(90deg);
        @media(max-width: 576px) {
            width: 14px;
        }
    }

    .hero-heading {
        font-size: 64px;
        font-weight: 400;
        max-width: 1000px;
        line-height: 72px;
        margin-bottom: 0.5em;
        @media (max-width: 576px) {
            line-height: 44px;
            font-size: 32px;
         }
    }
    h5 {
        @media (max-width: 576px) {
            font-size: 16px;
        }
    }
    .detail {
        font-weight: 600;
    }
    .details {
        max-width: 360px;
    }
    .details span {
        font-weight: 700;
    }
    .hero-container {
        padding-top: 15em;
        
        display: flex;
        flex-direction: column;
        @media (max-width: 600px) {
            padding-top: 6rem;
        }
    }
    hr {
        border: 1px solid black;
        margin: 80px 0;
        @media (max-width: 600px) {
            margin: 40px 0;
        }
    }
    p {
        margin-bottom: 0;
        margin-top: 2em;
    }
    ul {
        padding-left: 32px;
        @media (max-width: 600px) {
            padding-left: 20px;
        }
    }
    li {
        margin-bottom: 0.5em;
    }
`;

const HeroSection = (props) => {
    const [details, setDetails] = useState(null)

    useEffect(() => {
        function handleResize() {
            setDetails(
                (window.innerWidth>992)
                ?(
                    <div className='row'>
                        <div className='col-lg-3 col-12 details mb-2'>
                            <h5>Location</h5>
                            <span className='paragraph'>{props.job.location}</span>
                        </div>
                        <div className='col-lg-3 col-12 details mb-2'>
                            <h5>Date Posted</h5>
                            <span className='paragraph'>{props.job.date}</span>
                        </div>
                        <div className='col-lg-3 col-12 details mb-2'>
                            <h5>Employment Type</h5>
                            <span className='paragraph'>{props.job.type}</span>
                        </div>
                    </div>
                ):(
                    <div className='row details'>
                        <div className='col-6 mb-2'>
                            <h5>Location</h5>
                            <h5>Date Posted</h5>
                            <h5>Employment Type</h5>
                        </div>
                        <div className='col  mb-2'>
                            <h5 className='detail'>{props.job.location}</h5>
                            <h5 className='detail'>{props.job.date}</h5>
                            <h5 className='detail'>{props.job.type}</h5>
                        </div>
                    </div>
                )
            )
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return (()=>window.removeEventListener('resize', handleResize))
    }, [props.job])

    return (
        <StyledWrapper className='background-dot color-black'>
            <div className="container hero-container">
            <div className='row mb-3'>
                    <div className='col-12'>
                        <Link to='/careers/'>
                            <button className='text-btn background-white color-black'>
                                <img src={arrow} alt='&lt;'/>
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-9 pr-0">
                        <h1 className="hero-heading">{props.job.title}</h1>
                    </div>
                </div>
                {details}
                
                <div className='row mt-5'>
                    <div className='col-lg-10 paragraph'>
                        <BlockContent blocks={props.job._rawOverview} />
                    </div>
                    <hr className='divider'/>
                </div>
                <div className='row'>
                    <div className='col-lg-10 paragraph'>
                        <h2 className='mb-5'>Responsibilities</h2>
                        <BlockContent  blocks={props.job._rawResponsibilities} />
                    </div>
                    <hr className=' divider'/>
                </div>
                <div className='row'>
                    <div className='col-lg-10 paragraph'>
                        <h2 className='mb-5'>Requirements</h2>
                        <BlockContent blocks={props.job._rawRequirements} />
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default HeroSection
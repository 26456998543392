import React from "react";
import App from "./App";
import { Helmet } from "react-helmet";

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import {useStaticQuery,graphql} from 'gatsby'

// markup
const IndexPage = () => {
  const query = useStaticQuery(graphql`
  query index {
    allSanityHomepage {
      edges {
        node {
          SEOtitle
        }
      }
    }
  }
  `)
  const {allSanityHomepage:{edges:{0:{node:{SEOtitle:SEOtitle}}}}}=query;
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEOtitle}</title>
      </Helmet>
      <link rel="canonical" href="https://10club.team" /> <App />
    </main>
  );
};

export default IndexPage;

import React from 'react'
import styled from "styled-components"
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from "gatsby-plugin-image"
import PortraitCarousel from './PortraitCarousel';





const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-x: hidden;

    .row-min-height {
        min-height: 576px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 576px) {
            min-height: 350px;
        }
    }

    .col-lg-8 {
        @media(min-width: 576px) {
            max-width: 68%;
        }
    }
`;

const CoFounders = ({slides,title,description}) => {

    return (
        <StyledWrapper className='background-dot'>
            <div className="container">
                <div className="row row-min-height">
                    <div className="col-12 col-lg-8">
                        <h2>{title}</h2>
                        <p className="paragraph mt-4">
                            {description}
                        </p>
                    </div>
                    <PortraitCarousel slides={slides}/>
                </div>
            </div>
        </StyledWrapper>
    )
}



export default CoFounders;

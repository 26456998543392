import React, { useEffect } from 'react'
import styled from "styled-components";

import { TeamPortrait } from '../Reusables/TeamPortrait';




const StyledWrapper = styled.div`
    background-size: 16px 16px;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-x: hidden;
    
    h1 {
        @media (max-width: 576px) {
            font-size: 32px;
        }
    }
    p {
        @media (max-width: 576px) {
            line-height: 18px;
        }
    }
`

const Leadership = ({team,founder,teamTitle,teamDesc,founderTitle,founderDesc}) => {
    useEffect(()=>{
        console.log("team",team)
    },[])
    const renderTeams = (member,i,mode) =>{
        let arr = ["Aditya Sarda","Vikas Sunkad","Purti V","Mona Bhageria Adurty","Pratik Sahu","Vaibhav Singhal","Varun Meda","Anju Shenoy","Sumit Agarwal","Ulaganathan N","Chirantan Chitale"]
        if(mode){
            if(arr.includes(member.MemberName)){
                return(
                    <div className='col' key={`Member${i}`}>
                        <TeamPortrait 
                            img={member.memberImage?.asset} 
                            name={member.MemberName} 
                            desg={member.position}
                            link={member.linkedInUrl}
                            bgColor={member.bgColor}
                        />
                    </div>
                )
            }
        }else{
            // console.log("non")
            if(!arr.includes(member.MemberName) && member.MemberName != ""){
                return(
                    <div className='col' key={`Member${i}`}>
                        <TeamPortrait 
                            img={member.memberImage?.asset} 
                            name={member.MemberName} 
                            desg={member.position}
                            link={member.linkedInUrl}
                            bgColor={member.bgColor}
                        />
                    </div>
                )
            }
        }
        
    }
    return (
        <StyledWrapper id='leadership' className='background-dot'>
            <div className='container'>
                <div className="row">
                    <div className='col-lg-9'>
                    <h2 className='mb-5'>Senior Leadership</h2>
                    <p className='paragraph'>
                        {/* {teamDesc} */}
                        We believe in true ownership, and that is why every leader on the 10club team manages a portfolio vertical with deep precision and focus. They make strategic decisions with velocity that is unmatched, that's what ensures there is magic at 10club every single day.
                    </p>
                    </div>
                </div>
                <div className='row row-cols-1 row-cols-xl-3 row-cols-md-2 mb-5'>
                    {
                        team.map((member, i) => 
                        renderTeams(member,i,"seniors")
                        )
                    }
                    
                </div>

                {/* addon */}
                <div className="row">
                    <div className='col-lg-9'>
                    <h2 className='mb-5'>{teamTitle}</h2>
                    <p className='paragraph'>
                        {/* {teamDesc} */}
                    </p>
                    </div>
                </div>
                <div className='row row-cols-1 row-cols-xl-3 row-cols-md-2 mb-5'>
                    {team.map((member, i) => renderTeams(member,i))}
                    {/* {
                        team.map((member, i) => (
                            <div className='col' key={`Member${i}`}>
                                <TeamPortrait 
                                    img={member.memberImage?.asset} 
                                    name={member.MemberName} 
                                    desg={member.position}
                                    link={member.linkedInUrl}
                                    bgColor={member.bgColor}
                                />
                            </div>
                        ))
                    } */}
                </div>
                {/* addon end */}

                <div className="row pt-5">
                    <div className="col-12 col-lg-8">
                        <h2>{founderTitle}</h2>
                        <p className="paragraph mt-4">
                            {founderDesc}
                        </p>
                    </div>
                </div>
                <div className='row row-cols-1 row-cols-xl-3 row-cols-md-2'>
                    {
                        founder.map((founder, i) => (
                            <div className='col' key={`Founder${i}`}>
                                <TeamPortrait 
                                    img={founder.coFounderImage.asset} 
                                    name={founder.coFounderName} 
                                    desg={founder.position}
                                    link={founder.linkedInUrl}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </StyledWrapper>
    )
}


export default Leadership;
import { Link } from "gatsby";
import React, { useState } from "react"
import styled from "styled-components";
import MobileMenu from './MobileMenu'
import blackLogo from "../images/icons/Logo.svg";
import whiteLogo from "../images/icons/whiteLogo.svg"
import blackhamburger from '../images/icons/Hamburger.svg'
import whiteHamburger from '../images/icons/whiteHamburger.svg'


const StyledWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  nav {
    display: flex;
    @media (max-width: 992px) {
      display: none;
    }
  }

  nav a {
    height: 64px;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border: none;
    transition: background-color 0.5s;
    transition: color 0.5s;
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  nav a {
    margin-right: 1em;
  }

  nav a:last-child {
    margin-right: 0;
  }

  .blackInactive {
    transition: background-color 0.5s;
    background-color: transparent;
    color: black;
  }

  .blackInactive:hover {
    background-color: #000;
    color: white;
  }

  .blackInactive:active {
    background-color: #828282;
    color: white;
  }

  .whiteInactive {
    transition: background-color 0.5s;
    background-color: transparent;
    color: white;
  }

  .whiteInactive:hover {
    background-color: #FFF;
    color: black;
  }

  .whiteInactive:active {
    background-color: #BDBDBD;
    color: black;
  }
  
  

  .logo {
    transition: opacity 0.5s;
    @media (max-width: 576px) {
        width: 102px;
        height: 40px; 
    }
  }

  .logo:hover {
      opacity: 0.7;
  }

  .menuBtn {
    display: none;
    border-radius: 50%;
    border: none;
    width: 50px;
    height: 50px;
    @media (max-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 576px) {
      height: 40px;
      width: 40px;
    }
  }

  & .blackActive {
    transition: background-color 0.5s;
    background-color: black;
    color: white;
  }

  & .blackActive:hover {
    background-color: #4F4F4F;
  }

  & .blackActive:active {
    background-color: #828282;
  }

  & .whiteActive {
    transition: background-color 0.5s;
    background-color: white; 
    color: black;
  }

  & .whiteActive:hover {
    background-color: #E0E0E0; 
  }

  & .whiteActive:active {
    background-color: #BDBDBD; 
  }
`

export default function Header(props) {
  const logo = (props.bgBlack)
                ?whiteLogo
                :blackLogo

  const hamburger = (props.bgBlack)
                    ?blackhamburger
                    :whiteHamburger
  
  const buttonBG = (props.bgBlack)
                    ?`white`
                    :`black`

  const [hamburgerToggle, setHamburgerToggle] = useState(false);

  return (
    <StyledWrapper className='container'>
      <div className="row">
          <div className="col-12 space-between">
              <Link to='/'><img className="img-fluid logo" src={logo} alt="" /></Link>
              <nav>
                {/* <Link to='/seller-stories/'
                activeClassName={` ${buttonBG}Active`}
                className={` ${buttonBG}Inactive paragraph`}>
                      Seller Stories
                </Link> */}
                <Link to='/products/'
                activeClassName={` ${buttonBG}Active`}
                className={` ${buttonBG}Inactive paragraph`}>
                  Products
                </Link>
                <Link to="/team/"
                activeClassName={` ${buttonBG}Active`}
                className={` ${buttonBG}Inactive paragraph`}>
                      Team
                </Link>
                <Link to="/careers/" 
                activeClassName={` ${buttonBG}Active`} 
                partiallyActive={true}
                className={` ${buttonBG}Inactive paragraph`}>
                      Careers
                </Link>
                {/* <a href="https://airtable.com/shriufRX917RXhi7P" 
                target="_blank" 
                rel="noopener noreferrer"
                className={`${props.getInTouchActive?`${buttonBG}Active`:`${buttonBG}Inactive`} paragraph`}>
                  Get in Touch
                </a> */}
              </nav>
              <button className={`menuBtn ${buttonBG}Active`} onClick={() => setHamburgerToggle(true)}>
                <img src={hamburger} alt='+'/>
              </button>
              {hamburgerToggle && <MobileMenu toggle={setHamburgerToggle}/>}
          </div>
      </div>
    </StyledWrapper>
  )
}
import { Link } from 'gatsby';
import React from 'react';
import styled from "styled-components";
import logo from "../images/icons/whiteLogo.svg";

const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    img {
        @media (min-width: 576px) {
            height: 48px;
       }
    }

    .footer-links{
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media (max-width: 576px) {
        font-size: 16px;
        line-height: 20px;
        display:block;
        }
    }

    & .footer-links:hover{
        opacity:0.5;
        color:white;
    }

    .footer-class{
        display:flex;
        justify-content:space-around;
        @media(max-width: 770px){
            display:block;
        }
    }
`;

const Footer = () => {
    return (    
        <StyledWrapper className='background-black'>
            <div className="container ">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <Link to='#'>
                        <img className="mx-auto logo mb-3 mb-md-0" src={logo} alt=""/>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 pt-3">
                        <span className="paragraph color-white order-2 order-md-1">©10club 2022</span> 
                    </div>
                    <div className="col-12 col-md-4 col-lg-6 pt-3 footer-class">
                        <Link className="color-white footer-links" to="/team/">Team</Link>
                        <Link className="color-white footer-links" to="/careers/" >Careers</Link>
                        <a className="color-white footer-links" rel="noopener noreferrer" target="_blank" href="https://drive.google.com/file/d/17ZtWwPriJEvojxKaM7HrJKZCae8UdMVn/view?usp=sharing">Labour Law Compliance</a>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default Footer;
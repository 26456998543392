import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { useForm, ValidationError } from '@formspree/react';
import uploadIcon from '../../images/icons/upload.svg'
import loadingGIF from '../../images/loading.gif'
import { Link } from 'gatsby';


const StyledWrapper = styled.div`
    padding-top: 80px;
`;

const ContactForm = styled.div`
    border-radius: 4px;

    form section {
      margin-bottom: 1rem;
      
    }

    .or {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25%;
        & hr {
          flex-grow: 1;
        }
    }

    .nameFields {
      display: flex;
      gap: 2rem;
      @media(max-width: 576px) {
        flex-direction: column;
        gap: 1rem;
      }
    }

    label {
      position: relative;
    }

    .required:after {
      display: inline-block;
      content: '';
      background-color: #ED7575;
      position: relative;
      left: 10px;
      bottom: 2px;
      width: 5px;
      height: 5px;
      border-radius: 100%;
    }
    .join-us-form {
        display: flex;
        flex-direction: column;
    }

    input {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 54px;
        padding-left: 10px;
        margin: auto auto 10px auto;
    }

    input::placeholder {
      font-size: 14px;
    }

    button {
        width: max-content;
        align-self: flex-end;
    }

    .fileUpload {
        border: 2px dotted #000000;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        min-height: 54px;
        padding: 0 10px;
        margin: 0.5rem auto 10px auto;
        display: flex;
        align-items: center;
        color: rgba(33, 37, 41, 0.65);
        font-size: 14px;
        justify-content: space-between;
        &:hover {
            background-color: #e0e0e0;
        }
        &:active {
            background-color: #BDBDBD;
        }
    }

    .bold {
        color: rgba(33, 37, 41, 1);
        font-size: initial;
    }

    .borderRed {
        border-color: red;
    }

    .loading {
        width: 24px;
    }

    #attachment {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
    }
`;

const JoinUs = (props) => {
    const [state, handleSubmit] = useForm("myylwlpb");
    const [fileUploadText, setFileUploadText] = useState("(Please upload a .pdf file under 25MB)")
    const [sending, setSending] = useState(false)
    const fileInputRef = useRef(null)
    const fileTxtRef = useRef(null)
    const linkedInRef = useRef(null)

    useEffect(() => {
        if (state.errors !== [])
        {
            console.log(state.errors)
            setSending(false)
        }
    }, [state.errors])

    const handleChange = () => {
        const file = fileInputRef.current.files[0];
        if(file && file.type === 'application/pdf' && file.size < 26214400) {
            setFileUploadText(`${file.name} (${(file.size / (1024*1024)).toFixed(2)} MB)`)
            fileTxtRef.current.className = 'fileUpload bold'
        }
        else {
            setFileUploadText('(Please upload a .pdf file under 25MB)')
            alert("Please upload a .pdf file under 25MB")
        }
    }

    const submit = (e) => {
        e.preventDefault();

        if (fileInputRef.current.value === "") {
            fileTxtRef.current.className = 'fileUpload borderRed'
           
        }

        else {
            fileTxtRef.current.className = 'fileUpload'
            setSending(true)
            handleSubmit(e)
        }
        
    }
    console.log('testing')

    if (state.succeeded) {
        return (
            
            <StyledWrapper className='background-black color-white'>
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <h2 >Thanks for getting in touch!</h2>
                            <p className='paragraph'>We will review your application and get back to you shortly.</p>
                            <Link to='/careers'>
                                <button id="contact-submit" 
                                type="submit" 
                                className="text-btn background-white color-black mt-5" 
                                disabled={state.submitting}>
                                        Back To Careers Page
                                </button>
                            </Link>
                            <hr className='divider' />
                        </div>
                    </div>
                </div>
            </StyledWrapper>
            )
    }

    return (
        <StyledWrapper className='background-black'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-5 mb-md-0 mb-5">
                        <h2 className="color-white mb-4">Interested?</h2>
                        <p className="paragraph color-white entrepreneur">
                            Drop us your details and we will get back to you!
                        </p>
                    </div>
                    <div className="col-12 col-md-7 d-flex flex-column justify-content-center">
                    <ContactForm id="contact" className='background-white p-sm-5 p-3'>
                        <form className="join-us-form" onSubmit={(e)=>submit(e)}>
                            {/*Name */}
                            <section className='nameFields'>
                            {/*First Name*/}
                            <div>
                                <label htmlFor='_fname' className='required'>First Name</label>
                                <input
                                id="name"
                                name="First Name"
                                pattern={`^[a-zA-Z\-\']+$`}
                                placeholder=""
                                required
                                />
                                <ValidationError 
                                prefix="_fname" 
                                field="_fname"
                                errors={state.errors}
                                />
                            </div>

                            {/*last Name*/}
                            <div>
                                <label htmlFor='_lname' className='required'>Last Name</label>
                                <input
                                id="lname"
                                pattern={`^[a-zA-Z\-\']+$`}
                                name="Last Name"
                                placeholder=""
                                required
                                />
                                <ValidationError 
                                prefix="_lname" 
                                field="_lname"
                                errors={state.errors}
                                />
                            </div>
                            
                        </section>
                            
                            {/*Contact */}
                            <section>
                            <label htmlFor='contact' className='required'>Contact</label>
                            <input
                            id="contact"
                            type="contact" 
                            name="Contact"
                            placeholder="e.g. +91 1234567890"
                            pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                            required
                            />
                            <ValidationError 
                                prefix="contact" 
                                field="contact"
                                errors={state.errors}
                            />
                            </section>

                            {/*Email */}
                            <section>
                                <label htmlFor="email" className='required'>
                                Email Address
                                </label>
                                <input
                                id="email"
                                name="Email"
                                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                placeholder="e.g. youremail@domain.com"
                                required
                                /> 
                                <ValidationError 
                                prefix="This field"
                                field="email"
                                errors={state.errors}
                                />
                            </section>
                        
                            {/*Linkedin */}
                            <section >
                            <label htmlFor='linkedin'>
                                Your LinkedIn Profile
                            </label>
                            <input
                                id="linkedin"
                                name="Linkedin Profile"
                                pattern="^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/[\S]+"
                                placeholder="e.g. https://www.linkedin.com/in/yourprofileid"
                                ref={linkedInRef}
                                onClick={()=> {
                                    linkedInRef.current.className = ""
                                    fileTxtRef.current.className = "fileUpload"
                                }}
                            />
                            <ValidationError 
                                prefix="Linkedin" 
                                field="linkedin"
                                errors={state.errors}
                            />
                            </section>                     

                            {/*Resume File Upload*/}
                            <section>
                                <label htmlFor='attachment' className='w-100'>
                                    <span className='required'>Your Resume </span>
                                    <input 
                                        id="attachment"
                                        type="file"
                                        name="attachment" 
                                        accept="application/pdf"
                                        ref={fileInputRef}
                                        onChange={()=>handleChange()}
                                    />
                                    <ValidationError 
                                        prefix="attachment" 
                                        field="attachment"
                                        errors={state.errors}
                                    />
                                    <div className='fileUpload' ref={fileTxtRef}>
                                        {fileUploadText}
                                        <img src={uploadIcon} alt='' />
                                    </div>
                                </label>
                            </section>

                            <input
                                type="hidden"
                                id="linkedin"
                                name="Position"
                                value={props.jobTitle}
                            />
                            <ValidationError 
                                prefix="Message" 
                                field="message"
                                errors={state.errors}
                            />

                            {
                                sending
                                ?(<h5 className='align-self-end'>
                                    Sending <img src={loadingGIF} alt='' className='loading'/>
                                </h5>)
                                :(<button id="contact-submit" 
                                type="submit" 
                                className="text-btn background-black color-white mt-3" 
                                disabled={state.submitting}>
                                    Submit
                                </button>)
                            }
                        </form>
                        </ContactForm>
                    </div>
                    <hr className="divider"/>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default JoinUs
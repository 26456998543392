import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import GlobalStyle from "../globalStyle";

//Required... for some reason...
import "bootstrap/dist/css/bootstrap.css";

// styles
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

// markup
const NotFoundPage = () => {
  return (
    <>
      <GlobalStyle />
      <StyledWrapper className="background-dot">
        <h1>Page not found</h1>
        <p className="paragraph">
          Sorry, we couldn’t find what you were looking for.
        </p>
        <Link to="/">
          <button className="text-btn background-black color-white">
            Back To Home
          </button>
        </Link>
      </StyledWrapper>
    </>
  );
};

export default NotFoundPage;

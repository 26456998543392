import React from "react";
import GlobalStyle from "../globalStyle";

import "bootstrap/dist/css/bootstrap.css";
import Header from "./Header";
import FAB from "./FAB";
import Footer from "./Footer";

export default function Layout(props) {
  return (
    <div>
      <GlobalStyle />

      <Header
        bgBlack={props.bgBlack}
        getInTouchActive={props.getInTouchActive}
      />
      {props.children}
      <FAB />
      <Footer />
    </div>
  );
}

import { Link } from 'gatsby';
import React from 'react'
import styled from "styled-components";

const StyledWrapper = styled.div`
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;

    article {
        display: flex;
        border-radius: 4px;
        height: 594px;
    }
    
    article .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 80px 0 80px 80px;
        align-items: flex-start;
    }

    article .image {
        height: 100%;
        flex-grow: 2;
        width: 100%;
        background-color: lightgrey;
    }
`;



const SellersStories = () => {

    return (
        <StyledWrapper className='background-black color-white' id='process'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 mb-5">
                        <h2>Join our growing list of satisfied sellers</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <article className='background-white color-black'>
                            <div className='content'>
                                <h2>Lorem ipsum Company Name consectetur adipiscing elit sed do</h2>
                                <Link to="/seller-stories/">
                                    <button className='text-btn background-black color-white'>
                                        Read More
                                    </button>
                                </Link> 
                            </div>
                            <div className='image'></div>
                        </article>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default SellersStories;
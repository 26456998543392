import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useEmblaCarousel } from "embla-carousel/react";

import whiteDownArrow from "../../images/icons/whiteDownArrow.svg";
import BlockContent from "@sanity/block-content-to-react";

const StyledWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  overflow-x: hidden;

  .carousel {
    position: relative;
  }

  .embla {
    overflow: hidden;
    background-color: white;
    cursor: grab;
    @media (max-width: 1200px) {
      overflow: unset;
      background-color: unset;
    }
  }

  .embla__viewport {
    width: 100%;
    border: 2px solid;
    border-radius: 4px;
    @media (max-width: 1200px) {
      border: none;
    }
  }

  .embla__container {
    display: flex;
    width: 100%;
  }

  .embla__slide {
    position: relative;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 3em 5em;
    @media (max-width: 1200px) {
      border: 2px solid;
      border-radius: 4px;
      flex: 0 0 640px;
      padding: 2.5em 2em;
      margin-right: 20px;
      background-color: white;
      justify-content: flex-start;
    }
    @media (max-width: 576px) {
      flex: 0 0 280px;
    }
  }

  .embla__button {
    margin-top: unset;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .embla__button img {
    width: 17px;
  }

  #leftArrow {
    transform: rotate(90deg);
  }

  #rightArrow {
    transform: rotate(-90deg);
  }

  .embla__prev {
    left: -2em;
  }
  .embla__next {
    right: -2em;
  }
  .embla__button:disabled {
    display: none;
  }

  ul {
    padding-left: 32px;
    @media (max-width: 576px) {
      padding-left: 20px;
    }
  }

  li {
    @media (max-width: 1200px) {
      margin-bottom: 1em;
    }
  }
`;

export const CulturePhilosophy = ({ title, description, carouselData }) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  
  const getComp = (principle) => {
    return <BlockContent blocks={principle._rawCarouselDesc} />;
  };
  return (
    <StyledWrapper className="background-dot">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h2 className="mb-5">{title}</h2>
            <BlockContent className="paragraph" blocks={description} />
          </div>
        </div>
        <div className="row carousel mt-5 mx-1">
          <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {carouselData.map((principle, i) => {
                  return (
                    <div className="embla__slide" key={`principle${i}`}>
                      <h2>{principle.carouselTitle}</h2>
                      <div className="paragraph mt-3">{
                        (()=>getComp(principle))()
                      }</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <button
            className="round-btn background-black embla__button embla__prev "
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
          >
            <img src={whiteDownArrow} alt="&lt;" id="leftArrow" />
          </button>

          <button
            className="round-btn background-black embla__button embla__next"
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
          >
            <img src={whiteDownArrow} alt="&gt;" id="rightArrow" />
          </button>
        </div>
      </div>
    </StyledWrapper>
  );
};

import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { GridShowcase } from "../components/SellerStoriesPage/GridShowcase";
import HeroSection from "../components/SellerStoriesPage/HeroSection";
import JoinTheClub from "../components/SellerStoriesPage/JoinTheClub";
import { Press } from "../components/SellerStoriesPage/Press";
import { useStaticQuery, graphql } from "gatsby";
import { Companies } from "../components/SellerStoriesPage/Companies";

export default function Products() {
  // const query = useStaticQuery(graphql`
  //   query SellerStoriesQuery {
  //     allSanitySeller {
  //       edges {
  //         node {
  //           SEOtitle
  //           sellerTitle
  //           sellerGif {
  //             asset {
  //               url
  //             }
  //           }
  //           sellerDesc
  //           companyDetails {
  //             companyName
  //           }
  //           sellerArticle {
  //             articleImage {
  //               asset {
  //                 url
  //               }
  //             }
  //             companyName
  //             companyIndustry
  //             articleDescription
  //           }
  //           joinTheClubTitle
  //           jointheClubButton
  //           buttonLink
  //         }
  //       }
  //     }
  //   }
  // `);

  // const {allSanitySeller:{edges:{0:{node:pageData}}}}=query;

  const _heroData = {
    sellerTitle:"Making every home complete with our end-to-end home and kitchen products!",
    sellerDesc:" Having a comfortable and beautiful family home is everyone's dream. Fuelled by the aesthetic standards of western content, we offer a financially accessible, high quality range of products for your living room, kitchen, bedroom and bathroom across categories catering to every Indian household.",
    sellerGif:{
      asset:{
        url:"https://cdn.sanity.io/images/7d6an38d/main/491fd94c5e557ae3e0954b0abccffaac9052a1ff-722x452.png"
      }
      
    }
  }

  //sellerArticle {
    //             articleImage {
    //               asset {
    //                 url
    //               }
    //             }
    //             companyName
    //             companyIndustry
    //             articleDescription
    //           }
  const _proData = [
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/d4d12d67225314fb0a3cf3776d1ceba79e958a6d-504x284.jpg"
        },
      },
      companyName:"Home Kitchen",
      companyIndustry:"",
      articleDescription:"Bringing you only the best for the heart of the home. A modern range of durable kitchenware and cooking essentials to get the kitchen decked up and to make everyday cooking hassle-free and fun!"
    },
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/104eb1807cf0d126d2fd64eee917dd24c1a8b920-504x284.jpg"
        },
      },
      companyName:"Home Furnishing",
      companyIndustry:"",
      articleDescription:"Your chance to show off your creativity! Your space, our designs, your choice, our furnishings to bring comfort and convenience to the contemporary Indian household"
    },
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/8e703baaf9b57134c09aa63406cebf2d434edfb6-504x284.jpg"
        },
      },
      companyName:"Home & Outdoors (Lawn and Garden)",
      companyIndustry:"",
      articleDescription:"Build your perfect home garden with everything you need to become a pro! Everything from garden cultivating tools and accessories to planters and outdoor decor to help you beautify your space"
    },
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/ac6e4ccb39c0cf1f96bf44839c85a2169d8228c9-504x284.jpg"
        },
      },
      companyName:"Home - Baby Room",
      companyIndustry:"",
      articleDescription:"We help you bring joy to your kid’s room through colourful variants of our newborn baby and kids essentials including strollers, toys, feeding and grooming accessories. What’s more? Cute and functional blankets, wrappers and towels to keep your child warm and cosy!"
    },
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/2a98b2d8055126505f395fb6ed695df99b58cf9e-504x284.jpg"
        },
      },
      companyName:"Home Gym",
      companyIndustry:"",
      articleDescription:"One stop shop for your home gym including an affordable range of fitness accessories for beginners and advanced fitness enthusiasts; We help you get fit, healthy and energetic at the convenience of your home routine"
    },
    {
      articleImage:{
        asset:{
          url:"https://cdn.sanity.io/images/7d6an38d/main/f2b8754da0b57d1e3233f8953dcbfb563994edeb-504x284.jpg"
        },
      },
      companyName:"Home - General Merchandise",
      companyIndustry:"",
      articleDescription:"Tired of browsing for products to decorate your home? We got you covered with a range that completes your requirements to set up all spaces at home, from get go!"
    },
  ]
  

  return (
    <>
      <Layout bgBlack={true} getInTouchActive={false}>
        <HeroSection 
          heading={_heroData.sellerTitle} 
          subText={_heroData.sellerDesc} 
          gif={_heroData.sellerGif} 
        />
        <GridShowcase articles={_proData} titleDisabled />
        {/* <Companies companies={pageData.companyDetails}/> 
        

        <JoinTheClub title={pageData.joinTheClubTitle} btnLink={pageData.buttonLink} /> */}
      </Layout>
    </>
  );
}

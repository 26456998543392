import React from 'react'
import styled from "styled-components";
import DataAccordion from './Accordion';

const StyledWrapper = styled.div`
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;

    button {
        margin-top: 80px;
    }
    
    .number {
        display: inline-block;
        color: black;
        background-color: white;
        border-radius: 100px;
        width: 1.5em;
        min-width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        text-align: center;
        margin-right: 1em;
        flex-basis: auto;
    }

    .heading {
        white-space: nowrap;
        margin-bottom: 1em;
    }
    
`;



const Model = ({title,description,accordionData,buttonText}) => {

    return (
        <StyledWrapper className='background-black color-white' id='process'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 mb-5">
                        <h2>{title}</h2>
                        <p className="paragraph mt-4">
                            {description}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12'>
                        <DataAccordion content={accordionData}/>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-12 col-lg-8">
                        <a href="#contact">
                            <button className="background-white color-black text-btn">{buttonText}</button>
                        </a>
                    </div>
                </div> */}
            </div>
        </StyledWrapper>
    )
}


export default Model;

import React from 'react'
import styled from "styled-components";




const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 100%;
        max-width: 1140px;  
        @media (max-width: 1000px) {
            object-fit: none;
            height: 493px;
            object-position: center;
        }
    }
`;

const DataInsights = ({title,description,gif}) => {
    
    return (
        <StyledWrapper className='background-dot'>
            <div className="container">
                <div className='row'>
                    <div className='col-lg-8'>
                        <h2>{title}</h2>
                        <p className='paragraph mt-4'>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
            {gif && gif.asset && gif.asset.url && <img className='img-fluid mt-5' src={gif.asset.url} alt=''/>}
        </StyledWrapper>
    )
}

export default DataInsights;
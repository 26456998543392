import React from 'react';
import styled from "styled-components";

const StyledWrapper = styled.div`
    padding-top: 80px;
`;

const JoinUs = ({title,description,buttonText,link}) => {
    return (
        <StyledWrapper className="background-white color-black background-dot" id='contact'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h2 className="mb-4">{title}</h2>
                        <p className="paragraph mb-0">
                            {description}
                        </p>
                        {/* <a href={link} target="_blank" rel="noopener noreferrer">
                            <button className="text-btn background-black color-white mt-4" >
                                {buttonText}
                            </button>
                        </a> */}
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <hr className='divider'/>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default JoinUs

import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import HeroSection from "../components/TeamPage/HeroSection";
import Leadership from "../components/TeamPage/Leadership";
import CareersJoin from "../components/TeamPage/CareersJoin";
import {useStaticQuery,graphql} from 'gatsby'

export default function Team() {

  const query = useStaticQuery(graphql`
    query TeamPageQuery {
      allSanityTeam {
        edges {
          node {
            SEOtitle
            heroTitle
            heroHeading
            heroSubtext
            heroGif {
              asset {
                url
              }
            }
            coFounderTitle
            coFounderDesc
            coFounder {
              coFounderName
              position
              linkedInUrl
              coFounderImage {
                asset {
                  url
                }
              }
            }
            teamTitle
            teamDesc
            team {
              MemberName
              linkedInUrl
              bgColor
              position
              memberImage {
                asset {
                  url
                }
              }
            }
            careerTitle
            careerDesc
            careerButton
            careerLink
          }
        }
      }
    }
  `)
  const {allSanityTeam:{edges:{0:{node:pageData}}}}=query;
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.SEOtitle}</title>
      </Helmet>
      <Layout bgBlack={true} getInTouchActive={false}>
        <HeroSection title={pageData.heroTitle} heading={pageData.heroHeading} subText={pageData.heroSubtext} gif={pageData.heroGif}/>
        <Leadership team={pageData.team} founder={pageData.coFounder} teamTitle={pageData.teamTitle} teamDesc={pageData.teamDesc} founderTitle={pageData.coFounderTitle} founderDesc={pageData.coFounderDesc}/>
        <CareersJoin title={pageData.careerTitle} description={pageData.careerDesc} buttonText={pageData.careerButton} link={pageData.careerLink}/>
      </Layout>
    </>
  );
}

import { Link } from 'gatsby'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'

import arrow from '../images/icons/whiteDownArrow.svg'

const StyledWrapper = styled.div`
    position: fixed;
    opacity: 0;
    width: 100%;
    z-index: 3;
    bottom: 0;
    right: 0;
    transition: opacity 1s;
    margin-bottom: 110px;
    @media (max-width: 768px) {
        margin-bottom: 10px;
    }

    button {
        float: right;
        margin-top: 0;
        border: 2px solid white;
    }

    img {
        transform: rotate(180deg)
    }

    .fab-class{
        position: absolute;
        bottom: 20px;
        left:auto;
        @media(max-width:576px){
            right: 20px;
        }
        @media(min-width:1200px){
            right:200px;
        }
        right: 50px;
    }
`

const FAB = () => {

    const btnRef = useRef(null)

    useEffect(() => {
        function displayFAB() {
            if(window.scrollY>1700) {
                btnRef.current.style.opacity = 1
                btnRef.current.style.pointerEvents = 'fill'
            }
            else {
                btnRef.current.style.opacity = 0
                btnRef.current.style.pointerEvents = 'none'
            }
        }
        window.addEventListener('scroll', displayFAB) 

        return () => {
            window.removeEventListener('scroll', displayFAB)
        }
    }, [])


    
    return (
        <StyledWrapper ref={btnRef}>
            <div className="fab-class">
                        <Link to='#'>
                            <button className='round-btn background-black'>
                                <img src={arrow} alt=''/>
                            </button>
                        </Link>
            </div>
        </StyledWrapper>
    )
}

export default FAB

import React from 'react'
import styled from "styled-components";

import downArrow from '../../images/icons/downArrow.svg'


const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hero {
        padding-top: 8rem;
        padding-bottom: 4rem;
        @media (max-width: 991px) {
            padding-top: 6rem;
        }
    }

    .hero-heading {
        font-weight: 400;
        max-width: 1000px;
        line-height: 72px;
        margin-bottom: 0.5em;
        @media (max-width: 576px) {
            line-height: 44px;
         }
    }

    .hero-gif {
        padding-left: 2em;
        padding-right: 0;
        @media (max-width: 994px) {
            padding-left: 0;
            padding-top: 3em;
         }
    }
`;

const HeroSection = ({title,heading,subText,gif}) => {
    return (
        <StyledWrapper className='background-black color-white'>
            <div className="container">
                <div className="row align-items-center hero">
                    <div className="col-lg-5 pr-0">
                        <h1 className="hero-heading">{title}</h1>
                        <p className='paragraph'>
                            {heading}
                            <br/><br/>
                            {subText}   
                        </p>
                        <a href="#leadership">
                            <button className="background-white round-btn">
                                <img src={downArrow} alt="" />
                            </button>
                        </a>
                    </div>
                    {gif !== null ? <img src={gif.asset.url} alt='' className='img-fluid col-lg-7 hero-gif'/> : ''}
                </div>
            </div>
        </StyledWrapper>
    )
}

export default HeroSection

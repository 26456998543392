import React from 'react'
import styled from 'styled-components';


const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;

    .news-class{
        cursor:pointer;
        width:100%;
        @media(max-width: 767px){
            margin-bottom: 20px;
        }
    }

    .custom-border{
        @media(max-width: 767px){
            border-bottom:1px solid rgba(255,255,255,0.5);
        }
    }

    .press-img{
        height:280px;
        object-fit:cover;
        @media(min-width:991px){
            height:360px;
        }
    }

    .news-author{
        font-size:1rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        @media(max-width: 767px){
            font-size:12px;
        }
    }

    .pointer-cls{
        cursor:pointer;
    }

    .img-cls{
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`

const PressSection = ({press,title}) => {
    return(
        <StyledWrapper className="background-black color-white">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12 col-lg-9">
                        <h2>
                            {title}
                        </h2>
                    </div>
                </div>
                {React.Children.toArray(press.map(item=>(
                        <div className="row mb-4 pointer-cls" onClick={() => window.open(item.pressLink)}>
                            <div className="col-md-4 col-12">
                                <img src={item.pressImage.asset.url} alt="" className="img-cls mb-3 mb-md-0" />
                            </div>
                            <div className="col-md-8 col-12">
                                <h3>
                                    {item.pressHeading}
                                </h3>
                                <h5>
                                    {item.pressSubtext}
                                </h5>
                            </div>
                        </div>
                    )))}
            </div>
        </StyledWrapper>
    );
}

export default PressSection;
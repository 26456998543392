import { createGlobalStyle } from "styled-components";

import pattern from "./images/pattern.png";

import DMSansRegular_TTF from "./fonts/DMSans-Regular.ttf";
import DMSansRegular_WOFF2 from "./fonts/DMSans-Regular.woff2";
import DMSerifText_TTF from "./fonts/DMSerifText-Regular.ttf";
import DMSerifText_WOFF2 from "./fonts/DMSerifText-Regular.woff2";

const GlobalStyle = createGlobalStyle`

/*--- Font Setup ---*/

  @font-face {
    font-family: "DM Sans";
    src: url(${DMSansRegular_WOFF2}) format('woff2'),
         url(${DMSansRegular_TTF}) format('truetype');
  }

  @font-face {
    font-family: "DM Serif Text";
    src: url(${DMSerifText_WOFF2}) format('woff2'),
         url(${DMSerifText_TTF}) format('truetype');
  }

 html {
  scroll-behavior: smooth;
 }

  body {
    scroll-behavior: smooth;
    font-family: 'DM Sans', sans-serif;
  }

  .container {
    @media (max-width: 576px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }


/*--- Typography ---*/

  h1, h2 {
    font-family: 'DM Serif Text', serif;
  }

  h1 {
    font-size: 58px;
    line-height: 82px;
    @media (max-width: 576px) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 66px;
    @media (max-width: 576px) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  h5 {
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .paragraph {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }


/*--- Macros / Misc ---*/

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .color-black {
    color: black;
  }

  .color-white {
    color: white;
  }

  .background-black {
    background-color: black;
  }

  .background-white {
    background-color: white;
  }

  .background-dot {
    background-image: url(${pattern});
    background-repeat: repeat;
    background-size: 16px 16px;
  }

  .divider {
    border: 1px solid white;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;
  }


  /*--- Interactive ---*/

  .email-link {
    text-decoration: underline;
  }

  .email-link:hover {
    text-decoration: underline;
    color: white;
  }

  .round-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    width: 64px;
    height: 64px;
    margin-top: 2rem;
    transition: background-color 0.25s;
    @media (max-width: 576px) {
        width: 40px;
        height: 40px;
        padding: 0;
    }
  }

  .text-btn {
    height: 64px;
    font-size: 24px;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border: none;
    transition: color 0.5s;
    @media (max-width: 576px) {
      height: 40px;
      font-size: 14px;
      padding: 4px 12px;
    }
  }

  button.background-white:hover {
    transition: background-color 0.5s;
    background-color: #E0E0E0;
  }

  button.background-black:hover {
    transition: background-color 0.5s;
    background-color: #4F4F4F;
  }

  button.background-white:active {
    background-color: #BDBDBD;
  }

  button.background-black:active {
    background-color: #828282;
  }

  .logo {
    transition: opacity 0.5s;
    @media (max-width: 768px) {
        width: 102px;
        height: 40px; 
    }
  }

  .logo:hover {
      opacity: 0.7;
  }
`;

export default GlobalStyle;

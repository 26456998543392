import React from 'react'
import styled from 'styled-components'


const StyledWrapper = styled.div`
    padding-top: 80px;
`

const CareersJoin = ({title,description,buttonText,link}) => {

    return (
        <StyledWrapper className='background-black color-white'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8 '>
                    <h2 className='mb-5'>{title}</h2>
                    <p className='paragraph'>
                        {description}
                    </p>
                    <a href={link}>
                        <button className="text-btn background-white color-black mt-5">
                            {buttonText}
                        </button>
                    </a>
                    </div>
                </div>
                <div className='row'>
                    <hr className='divider'/>
                </div>
            </div>
        </StyledWrapper>
    )
}

export default CareersJoin;
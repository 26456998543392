import React from "react";
import Layout from "../components/Layout";
import { GridShowcase } from "../components/SellerStoriesPage/GridShowcase";
import HeroSection from "../components/SellerStoriesPage/HeroSection";
import JoinTheClub from "../components/SellerStoriesPage/JoinTheClub";
import { Press } from "../components/SellerStoriesPage/Press";
import { useStaticQuery, graphql } from "gatsby";
import { Companies } from "../components/SellerStoriesPage/Companies";

export default function SellerStories() {
  const query = useStaticQuery(graphql`
    query SellerStoriesQuery {
      allSanitySeller {
        edges {
          node {
            SEOtitle
            sellerTitle
            sellerGif {
              asset {
                url
              }
            }
            sellerDesc
            companyDetails {
              companyName
            }
            sellerArticle {
              articleImage {
                asset {
                  url
                }
              }
              companyName
              companyIndustry
              articleDescription
            }
            joinTheClubTitle
            jointheClubButton
            buttonLink
          }
        }
      }
    }
  `);

  const {allSanitySeller:{edges:{0:{node:pageData}}}}=query;

  
  

  return (
    <>
      <Layout bgBlack={true} getInTouchActive={false}>
        
        <HeroSection heading={pageData.sellerTitle} subText={pageData.sellerDesc} gif={pageData.sellerGif} />
        <Companies companies={pageData.companyDetails}/> 
        <GridShowcase articles={pageData.sellerArticle} />
        {/* <Press /> */}
        <JoinTheClub title={pageData.joinTheClubTitle} btnLink={pageData.buttonLink} />
      </Layout>
    </>
  );
}

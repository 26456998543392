import React from "react";
import Layout from "../components/Layout";
import HeroSection from "../components/CareersPage/HeroSection";
import { CulturePhilosophy } from "../components/CareersPage/CulturePhilosophy";
import { OpenPositions } from "../components/CareersPage/OpenPositions";
import { Helmet } from "react-helmet";
import {useStaticQuery,graphql} from 'gatsby'

export default function Careers({pageContext: { allJobs }}) {

  const query = useStaticQuery(graphql`
  query career {
    allSanityCareer {
      edges {
        node {
          SEOtitle
          careerTitle
          careerDesc
          careerGif {
            asset {
              url
            }
          }
          cultureTitle
          _rawCultureDesc
          carousel {
            carouselTitle
            _rawCarouselDesc
          }
          jobList {
            title
            category
            location
            date
            type
            slug
            _rawOverview
            _rawResponsibilities
            _rawRequirements
          }
          _rawContactUs
          positionTitle
          positionDesc
        }
      }
    }
  }
`)


function onlyUnique(value, index, self) {
  return self.findIndex(i => i.category == value.category) === index;
}
  const {allSanityCareer:{edges:{0:{node:pageData}}}}=query;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageData.SEOtitle}</title>
      </Helmet>
      <Layout bgBlack={true} getInTouchActive={false}>
        <HeroSection title={pageData.careerTitle} description={pageData.careerDesc} Gif={pageData.careerGif}/>
        <CulturePhilosophy title={pageData.cultureTitle} description={pageData._rawCultureDesc} carouselData={pageData.carousel}/>
        <OpenPositions title={pageData.positionTitle} description={pageData.positionDesc} allJobsUnique={pageData.jobList.filter(onlyUnique)} allJobs={pageData.jobList} category={pageData.jobList.category} contactUs={pageData._rawContactUs}/>
      </Layout>
    </>
  );
}

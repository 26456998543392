import React, { useEffect } from 'react'
import styled from "styled-components";

import { TeamPortrait } from './TeamPortrait';
import { useEmblaCarousel } from 'embla-carousel/react'


const StyledWrapper = styled.div`

    h3 {
        line-height: 20px;
    }

    .embla__container {
        display: flex;
        will-change: transform;
    }
    .embla__slide {
        position: relative;
        flex: 0 0 auto;
    }
`;

const PortraitCarousel = ({slides}) => {

    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: 'start',
        dragFree: true,
        draggable: true
      })

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('init', () => {
                if(window.innerWidth>1252)
                {    
                    emblaApi.reInit({ draggable: false });
                    emblaApi.scrollTo(0, true)
                }
                else
                    emblaApi.reInit({ draggable: true });
              });
            emblaApi.on('resize', () => {
                if(window.innerWidth>1252)
                {    
                    emblaApi.reInit({ draggable: false });
                    emblaApi.scrollTo(0, true)
                }
                else
                    emblaApi.reInit({ draggable: true });
              });
        }
    }, [emblaApi])

    return (
        <StyledWrapper>
            <div className='row px-4 embla' data-aos-duration="2000" ref={emblaRef}>
                <div className="embla__container">
                    {
                        slides.map((c, i) => (
                            <div className="embla__slide" data-aos="fade-down" data-aos-duration={(i+1)*1000} key={`CoFounder${i}`}>
                                <TeamPortrait className="embla__slide" coFounder={true}
                                    img={c.coFounderImage.asset} 
                                    name={c.coFounderName}
                                    desg={c.position}
                                    link={c.linkedInUrl}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </StyledWrapper>
    )
}

export default PortraitCarousel;
import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import linkedinLogo from "../../images/icons/linkedin.png";

const StyledWrapper = styled.div`
  margin-top: 4em;
  max-width: 374px;
  margin-right: 2em;
  @media (max-width: 768px) {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-size: 26px;
  }

  a.team-profile-link,
  a.team-profile-link:hover {
    text-decoration: none;
    color: #212529;
    transition: opacity 0.5s;
  }

  a.team-profile-link:hover {
    opacity: 0.7;
  }

  .portrait {
    transition: opacity 0.5s;
  }

  .no-portrait {
    width: 100%;
    height: 374px;
    background-color: #ccc;
  }

  .portrait:hover {
    opacity: 0.9;
  }

  .portrait:active {
    opacity: 0.8;
  }

  .linkedin {
    height: 30px;
  }

  .desc {
    flex-wrap: nowrap;
  }

  .img-class{
    height: 318px;
    width:100%;
    object-fit:cover;
  }
`;

export const TeamPortrait = (props) => {
  return (
    <StyledWrapper
      style={props.coFounder ? { marginRight: "2em", marginLeft: "unset" } : {}}
    >
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        className="team-profile-link"
      >
        {props.img === "" ? (
          <div className="no-portrait mb-3"></div>
        ) : (
            <img className="mb-3 img-class" src={props.img && props.img.url && props.img.url} alt="" style={{background:`${props.bgColor}`}}/>
        )}
          
        <div className="desc space-between">
          <div>
            <h3>{props.name}</h3>
            <h5>{props.desg}</h5>
          </div>
          <img src={linkedinLogo} alt="" className="linkedin" />
        </div>
      </a>
    </StyledWrapper>
  );
};

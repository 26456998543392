import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";

import cross from "../../images/icons/cross.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#000000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    number: {
        display: 'inline-block',
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '100px',
        width: '1.5em',
        height: '1.5em',
        lineHeight: '1.5em',
        textAlign: 'center',
        marginRight: '0.5em'
    }
}));

const ExpandIcon = <img className="cross" src={cross} alt="" />;

export default function DataAccordion({content}) {
    const classes = useStyles();

    return (
        <StyledWrapper className={classes.root}>
            {React.Children.toArray(content.map((data, i) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={ExpandIcon}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}> 
                            <h2 className={classes.title}> 
                                <div className={classes.number}>{`0${i + 1}`}</div> 
                                {data.title} 
                            </h2></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <p className={`paragraph accordion-description pr-lg-5 pr-3`}>
                                {data.description}
                            </p>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )))}
        </StyledWrapper>
    );
}

const StyledWrapper = styled.div`

    h2 {
        margin-bottom: 0;
        @media (max-width: 390px) {
            font-size: 16px;
        }
        @media (max-width: 576px) and (min-width: 391px) {
            font-size: 20px; 
        }
        @media (max-width: 764px) and (min-width: 576px) {
            font-size: 32px;
        }
    }

    .MuiAccordionSummary-root, .MuiAccordionDetails-root {
        background-color: black;
        color: white;
    }

    .MuiAccordionSummary-content {
        padding-top: 20px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .accordion-description {
        padding-bottom: 40px;
        padding-right: 1.5em;

        @media (max-width: 576px) {
            padding-bottom: 0px;
        }
    }

    .MuiPaper-elevation1 {
        border-bottom: 1px solid white;
    }

    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform-origin: 68% 45%;
        transform: rotate(45deg);
    }

    .MuiIconButton-root {
        padding-top: 10px;
    }

    .cross {
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
        }
        @media (max-width: 770px) and (min-width: 601px) {
            width: 32px;
            height: 32px;
        }
    }

    .MuiAccordionSummary-content.Mui-expanded {
        @media (max-width: 576px) {
            margin: 0;
        }
    }

    .Mui-expanded {
        margin-top: 12px !important;
    }

    .MuiTypography-body1 {
        font-family: unset;
    }
`;
import React from 'react'
import styled from "styled-components";

import DataAccordion from './Accordion';



const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;

    .accordion {
        margin-bottom: 80px;
    }
`;


const AboutTenClub = ({title,description,accordionData,buttonText}) => {
    return (
        <StyledWrapper className="background-black color-white" id="aboutTenClub">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12 col-lg-9 ">
                        <h2>
                            {title}
                        </h2>
                    </div>
                </div>
                <div className="row accordion">
                    <div className="col-12">
                        <DataAccordion content={accordionData}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>{description}</h3>
                        <a href="#contact">
                            <button className="text-btn background-white color-black mt-3 ">
                                {buttonText}
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}


export default AboutTenClub;

import React from "react";
import styled, { withTheme } from "styled-components";
import Layout from "../components/Layout";
import JoinTheClub from "../components/SellerStoriesPage/JoinTheClub";
import close from "../images/icons/close.svg";
import share from "../images/icons/share.svg";
import back from "../images/icons/back.svg";

const StyledWrapper = styled.div`

  .container{
    padding-top: 160px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .image {
    height: 200px;
    width: 100%;
    background-color: #EBEBEB;
    position: relative;
    
  }

  .article-data {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color:white;
    
  }

  .article-company {
    font-size: 24px;
    color: black;
    margin-bottom:0px;
  }

  .article-industry {
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 10px;
    color: black;
  }

  .article-desc {
    font-size: 14px;
    font-weight: 500;
    color: black;
  }
  .row {
    position: absolute;
    right: 0px;
    flex-direction: row-reverse;
    margin-right: 0px;
  }

  .cross {
    margin: 20px;
    height: 14px;
    width: 14px;
  }

  .share {
    margin: 20px;
    height: 14px;
    width: 14px;
  }

  .back {
    margin: 20px;
    height: 14px;
    width: 14px;
  }

`;

const Article = () => {
  // const {
  //   state: {
  //     companyInfo: { industry, name, para },
  //   },
  // } = location;
  // console.log({ industry, name, para });
  return (
    <>
      <Layout bgBlack={true} getInTouchActive={false}>
        <StyledWrapper className="background-black color-white">
          <div className="container">
            <div className="col">
              <div className="image">
                <div className="row">
                  <img className="cross" src={close} alt="" />
                  <img className="share" src={share} alt="" />
                </div>
                <img className="back" src={back} alt="" />
              </div>
              <div className="article-data">
                <h2 className="article-company">Alpha Pack</h2>
                <caption className="article-industry ">Apparel</caption>
                <article className="article-desc">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in blandit massa enim nec dui ac orci phasellus"
                </article>
              </div>
            </div>
          </div>
        </StyledWrapper>
        <JoinTheClub />
      </Layout>
    </>
  );
};

export default Article;

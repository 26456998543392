import React from 'react'
import styled from "styled-components"

import PortraitCarousel from '../Reusables/PortraitCarousel';

//images
import teamDeepak from '../../images/team/CoFounders/Deepak.png';
import teamBhavna from '../../images/team/CoFounders/Bhavna.png';
import teamJoel from '../../images/team/CoFounders/Joel.png';
import CoFounders from '../../components/Reusables/CoFounders'
import { Link } from 'gatsby';



const StyledWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-x: hidden;

    .row-min-height {
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 600px) {
            min-height: 350px;
        }
    }

    .col-lg-8 {
        @media(min-width: 600px) {
            max-width: 68%;
        }
    }
`;


const SecretToSuccess = ({title,description,slides}) => {

    const coFounders = [
        {
            name: 'Deepak Nair',
            desg: 'Co-Founder',
            img: teamDeepak,
            link: 'https://www.linkedin.com/in/deepak-nair-9586b21'
        },
        {
            name: 'Bhavna Suresh',
            desg: 'Co-Founder',
            img: teamBhavna,
            link: 'http://linkedin.com/in/bhavnasc'
        },
        {
            name: 'Joel Ayala',
            desg: 'Co-Founder',
            img: teamJoel,
            link: 'https://www.linkedin.com/in/joel-ayala-375b3269'
        },
    ]

    return (
        <CoFounders slides={slides}
        title={title}
        desc={description}/>
    )
}

export default SecretToSuccess;

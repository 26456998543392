import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import arrow from "../../images/icons/rightArrow.svg";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import cross from "../../images/icons/cross.svg";

const StyledWrapper = styled.div`
  padding-top: 80px;
  background-color: black;
  .description {
    max-width: 35ch !important;
  }
  h3 {
    font-size: 32px;
    margin-bottom: 0;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  .job {
    border-bottom: 1px solid;
    align-items: center;
    padding: 40px 0;
    @media (max-width: 576px) {
      padding: 20px 0;
    }
  }

  .job:hover h3 {
    transition: font-size 0.2s;
    font-size: 33px;
    @media (max-width: 576px) {
      font-size: 17px;
    }
  }
  .job:first-child {
    padding-top: 0;
  }
  .job:last-child {
    border-bottom: none;
  }
  hr {
    margin-top: 6rem;
    padding-top: 0;
  }
  img {
    @media (max-width: 576px) {
      height: 20px;
    }
  }
  a {
    text-decoration: none;
    color: white;
  }

  .contact > h2 > a {
    text-decoration: none;
  }

  .contact > h2 > a:hover {
    text-decoration: underline;
    color: white;
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#000000",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  number: {
    display: "inline-block",
    color: "black",
    backgroundColor: "white",
    borderRadius: "100px",
    width: "1.5em",
    height: "1.5em",
    lineHeight: "1.5em",
    textAlign: "center",
    marginRight: "0.5em",
  },
}));

const ExpandIcon = <img className="cross" src={cross} alt="" />;

export const OpenPositions = ({ title, description, allJobs, contactUs, allJobsUnique }) => {
  const classes = useStyles();

  const getJobs = (category) =>
    allJobs
      .filter((i) => i.category === category)
      .map((job, index) => {
        return (
          <Link
            to={`/careers/${job.slug}`}
            className="job space-between"
            key={`job${index}`}
          >
            <h3>{job.title}</h3>
            <img src={arrow} alt="->" />
          </Link>
        );
      });
      console.log(allJobs);
  return (
    <StyledWrapper className="color-white" id="openPositions">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-5">{title}</h2>
            <p className="paragraph description">{description}</p>
            <section className="py-5">
              <AccordionWrapper className={classes.root}>
                {React.Children.toArray(
                  allJobsUnique.map((data, i) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={ExpandIcon}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          <h2 className={classes.title}>
                            <div className={classes.number}>{`0${i + 1}`}</div>
                            {data.category}
                          </h2>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p
                            className={`paragraph accordion-description pr-lg-5 pr-3`}
                          >
                            {getJobs(data.category)}
                          </p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </AccordionWrapper>
            </section>

            <BlockContent className="color-white contact" blocks={contactUs} />

            <hr className="divider" />
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

const AccordionWrapper = styled.div`
  h2 {
    margin-bottom: 0;
    @media (max-width: 390px) {
      font-size: 16px;
    }
    @media (max-width: 576px) and (min-width: 391px) {
      font-size: 20px;
    }
    @media (max-width: 764px) and (min-width: 576px) {
      font-size: 32px;
    }
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    background-color: black;
    color: white;
  }

  .MuiAccordionSummary-content {
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: 576px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .accordion-description {
    padding-bottom: 40px;
    padding-right: 1.5em;

    @media (max-width: 576px) {
      padding-bottom: 0px;
    }
  }

  .MuiPaper-elevation1 {
    border-bottom: 1px solid white;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform-origin: 68% 45%;
    transform: rotate(45deg);
  }

  .MuiIconButton-root {
    padding-top: 10px;
  }

  .cross {
    @media (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
    @media (max-width: 770px) and (min-width: 601px) {
      width: 32px;
      height: 32px;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    @media (max-width: 576px) {
      margin: 0;
    }
  }

  .Mui-expanded {
    margin-top: 12px !important;
  }

  .MuiTypography-body1 {
    font-family: unset;
    width: 100%;
  }
`;

import { Link } from 'gatsby';
import React, { useEffect } from 'react'
import styled from "styled-components";
import logo from '../images/icons/whiteLogo.svg'
import closeIcon from '../images/icons/mobileMenuClose.svg'

const StyledWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0; 
    left: 0;
    height: 100%;
    min-height: -webkit-fill-available;
    width: 100vw;
    background-color: black;
    z-index: 5;
    padding-top: 80px;

    .closePosition {
        z-index: 10;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
    }

    .closeIcon {
        width: 50px;
        height: 50px;
        @media (max-width: 576px) {
            width: 40px;
            height: 40px;
        }
    }
    .list {
        display: flex;
        flex-direction: column;
    }
    button {
        color: white;
        background-color: black;
        border: none;
    }
    .list button {
        width: 100%;
        text-align: left;
        font-size: 32px;
        padding: 1rem 2rem;
        font-weight:700;

        @media (min-width:768px){
            font-size: 36px;
        }
    }
    .list button:active {
        background-color: #828282;
    }
    .white-logo {
        height: 32px;
        @media (min-width: 768px) {
            height: 48px;
        }
    }
    .menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 18px;
        padding-top: 20px;
        width: 100%;
        height: 100%;

        @media (min-width:768px){
            padding-right: 100px !important; 
            padding-left: 100px !important;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media (min-width:576px){
            padding-right: 20px;
            padding-left: 20px;
        }

    }
    
    .divider {
        margin-top: 2rem;
        margin-right: 0;
        margin-left: 0;
    }
`

export default function MobileMenu({toggle}) {

    useEffect(() => {
        //Prevent scrolling 
        document.body.style.overflow = 'hidden';

        const handleResize = () =>{
            if(window.innerWidth>992)
                toggle(false);
        }

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => { 
            window.removeEventListener("resize", handleResize); 
            document.body.style.overflow = 'unset';
        }
    }, [toggle])

    return (
        <StyledWrapper className='background-black color-white'>
            <div className='container closePosition'>
                <div className="row ">
                    <div className='col-12 '>
                        <button className='px-0' onClick={() => toggle(false)}>
                            <img className='closeIcon' src={closeIcon} alt='x'/>
                        </button>
                    </div>
                </div>
            </div>
            <div className='menu'>    
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12 px-0 list'>
                            <Link to='/'>
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Home
                                </button>
                            </Link>
                            <Link to='/products'>
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Products
                                </button>
                            </Link>
                            <Link to='/careers'>
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Careers
                                </button>
                            </Link>
                            <Link to='/team'>
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Team
                                </button>
                            </Link>
                            {/* <Link to='/seller-stories'>
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Seller Stories
                                </button>
                            </Link> */}
                            {/* <a href="https://airtable.com/shriufRX917RXhi7P" target="_blank" rel="noopener noreferrer">
                                <button className='listItem' onClick={() => {toggle(false)}}>
                                    Get In Touch
                                </button>
                            </a> */}
                        </div>
                    </div>
                </div>
                <div className='container-fluid' style={{padding: '0 2rem 1rem'}}>
                    <hr className='divider' />
                    
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-7">
                            <img className="white-logo mb-5" src={logo} alt=""/>
                        </div>
                        <div className="col-12 col-md-8 col-lg-3 pt-3">
                            <span className="paragraph color-white order-2 order-md-1">©10club 2021</span> 
                        </div>
                        <div className="col-12 col-md-8 col-lg-3 pt-3"> 
                            <a className="color-white paragraph email-link" href="mailto:team@10club.team">team@10club.team</a> 
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    )
}